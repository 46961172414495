import React from "react"
import styled from "styled-components"

import { MEDIA_MOBILE } from "../const"
import Metadata from "../components/metadata"
import Layout from "../components/layout"
import Heading2 from "../components/heading2"
import typography from "../utils/typography"

interface Props {
  location: Location
}
const Contact: React.FC<Props> = ({ location }) => (
  <Layout>
    <Metadata
      title="Contact"
      pagePath={location.pathname}
      description="お問い合わせ"
    />
    <Wrapper>
      <Heading2>Contact</Heading2>

      <h3>お問い合わせ</h3>
      <p>
        ファンシー・レコード合同会社
        <br />
        fancy.record.2020@gmail.com
      </p>

      <h3>郵便物宛先</h3>
      <p>
        〒150-0044
        <br />
        東京都渋谷区円山町６番７号
        <br />
        ファンシー・レコード合同会社
        <br />
        ぁゃぴ宛
      </p>
    </Wrapper>
  </Layout>
)

export default Contact

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }

  ${typography.toString()}

  p {
    line-height: 1.6;
  }
`
